import Cookies from 'js-cookie'
import queryString from 'query-string'
import mapKeys from 'lodash/mapKeys'

/**
 * TODO: Extract these into a "consts" file.
 */
export const BAMBUS_COOKIE_NO_TRACK = 'bmb_notrack'
export const BAMBUS_COOKIE_TRACK_LEVEL = 'bmb_tracklevel'
export const BAMBUS_COUNTRY_CODE = 'bmb_countrycode'
export const BAMBUS_GTM_COUNTRY_DATA_LAYER_VAR_KEY = 'bmb_country'
export const BAMBUS_GTM_AB_TESTING_DATA_LAYER_VAR_KEY = 'bmb_ab_test_name'
export const MATOMO_TRACKING_COOKIE_KEY = 'mtm_cookie_consent'

export const isBrowser = typeof window !== 'undefined'

const isDev = process.env.NODE_ENV === 'development'

const cookieSettings = {
  domain: isDev ? 'localhost' : '.bambus.io',
  expires: 28,
  secure: !isDev,
}

export const utmParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'countrycode',
]

export const BAMBUS_COUNTRY_CODE_QUERY_PARAM_KEY = 'countrycode'

export const getParams = (params = []) => {
  let obj = {}

  params.forEach((param) => {
    const p = getParam(param)
    if (p) {
      obj[param] = p
    }
  })

  return obj
}

export const getParam = (param) => {
  if (!isBrowser) {
    return
  }

  const queryParams = queryString.parse(window.location.search)

  const fromUrl = queryParams[param]
  const fromCookie = Cookies.get(param)

  if (fromUrl) {
    return fromUrl
  }

  if (fromCookie !== undefined) {
    return fromCookie
  }

  return null
}

export const cookieSetup = () => {
  const params = mapKeys(
    queryString.parse(window.location.search),
    (value, key) => key.toLowerCase()
  )

  utmParams.forEach((key) => {
    if (params[key] !== undefined) {
      Cookies.set(key, params[key], cookieSettings)
    }
  })
}

export const saveEntryURL = () => {
  if (localStorage) {
    localStorage.setItem('entryURL', window.location.href)
  }
}

export const getEntryURL = () => {
  if (localStorage) {
    return localStorage.getItem('entryURL')
  }
  return null
}
